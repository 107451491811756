<template>
	<div class="flex flex-row flex-wrap justify-start items-start w-screen sm:w-auto h-full px-4 py-6 sm:px-6 sm:py-12 gap-5">
		<router-link
			:to="{
				path: '/osmember/assignMO',
				query: {
					activeTab: activeTab
				}
			}"
			class="flex sm:hidden justify-center items-center border-2 border-blue-500 text-blue-500 font-semibold p-2 pl-3 rounded-xl"
		>
			<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M6 1L1.70711 5.29289C1.31658 5.68342 1.31658 6.31658 1.70711 6.70711L6 11" stroke="#3377FF" stroke-width="2" stroke-linecap="round" />
			</svg>
			<p class="pl-3">Назад</p>
		</router-link>

		<!-- Заголовок -->
		<h1 class="flex w-full text-xl font-extrabold sm:text-2xl">
			{{ nameMo }}
		</h1>

		<div class="flex flex-col w-full h-auto rounded-lg sm:rounded-t-none bg-white filter shadow-default">
			<!-- Заголовки -->
			<div class="sm:hidden thead content-center flex sm:hidden px-4 sm:px-8 sm:py-5 border-b sm:border-gray-100">
				<div class="w-8/12">
					<span class="table-cell w-full my-auto text-gray-400 cursor-pointer font-normal justify-between items-center"> Сотрудник ОС по НОК </span>
				</div>
				<div class="w-4/12">
					<span class="table-cell w-full my-auto text-gray-400 cursor-pointer font-normal justify-between items-center"> Назначить </span>
				</div>
			</div>
			<!-- Содержимое -->
			<div class="tbody sm:hidden content-center sm:hidden">
				<div v-for="(item, ind) in list" :key="'W' + ind" class="trow sm:px-8 w-full sm:border-b item-center sm:border-gray-100 text-gray-500">
					<div class="px-5 sm:p-0 w-full sm:w-3/12 flex justify-between items-center cursor-pointer border-b border-gray-100 sm:border-0">
						<span class="table-cell w-8/12">
							{{ item.fio }}
						</span>
						<span class="block w-4/12">
							<span class="table-cell">
								<div class="flex items-center justify-center w-full mb-12">
									<label :for="'toogle' + item.id" class="flex items-center cursor-pointer">
										<!-- toggle -->
										<div class="relative">
											<!-- input -->
											<input @change="setAnketaLinkForUserOS(medicalId, item)" :id="'toogle' + item.id" v-model="item.medicalOrgSet" type="checkbox" class="sr-only" />
											<!-- line -->
											<div class="w-10 h-4 bg-gray-200 rounded-full shadow-inner"></div>
											<!-- dot -->
											<div class="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
										</div>
									</label>
								</div>
							</span>
						</span>
					</div>
				</div>
			</div>
			<!-- navigation -->
			<div class="block sm:hidden flex-row pt-6 px-20 pb-12 text-gray-400 text-sm">
				<div class="flex flex-row ml-auto justify-around w-full">
					<div @click="page !== 0 ? navigation('params', page - 1) : ''" class="item flex bg-gray-100 w-9 h-9 text-center rounded-md hover:bg-blue-500 hover:color-white cursor-pointer">
						<svg class="m-auto" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M5 1L1.70711 4.29289C1.31658 4.68342 1.31658 5.31658 1.70711 5.70711L5 9" stroke="#A8B1CE" stroke-width="2" stroke-linecap="round" />
						</svg>
					</div>
					<div class="item flex bg-gray-100 w-24 h-9 text-center rounded-md hover:bg-blue-500 hover:color-white cursor-pointer">
						<span class="m-auto">Стр {{ page + 1 }} из {{ totalCount == 0 ? 1 : totalCount }}</span>
					</div>
					<div
						@click="page + 1 < totalCount ? navigation('params', page + 1) : ''"
						class="item next flex bg-gray-100 w-9 h-9 text-center rounded-md hover:bg-blue-500 hover:color-white cursor-pointer transform rotate-180"
					>
						<svg width="6" class="m-auto" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M5 1L1.70711 4.29289C1.31658 4.68342 1.31658 5.31658 1.70711 5.70711L5 9" stroke="#A8B1CE" stroke-width="2" stroke-linecap="round" />
						</svg>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import debounce from 'lodash.debounce';

// Глобальные данные стора
import { getters } from '@/store/store';

export default {
	name: 'SetMo',
	data() {
		return {
			url: '',
			nameMo: '',
			activeTab: null,
			medicalId: '',
			isLoading: true,
			totalCount: 0,
			rowsVisible: 100,
			page: 0,
			filterOpen: null,
			filterBy: null,
			filterData: {
				sortBy: 'asc',
				searchValue: ''
			},
			fields: [
				{
					Name: 'Сотрудник ОС по НОК',
					fieldName: 'Name',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Назначить',
					fieldName: 'Name',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				}
			],
			list: [],
			params: {
				filters: [
					{
						fieldName: 'YearStr',
						filterType: 2,
						filterValueType: 1,
						value1: '2022',
						value2: ''
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				pageSize: 100,
				currentPage: 0,
				regionId: null
			}
		};
	},
	computed: {
		...getters
	},
	methods: {
		switchFilterVisibility(key) {
			this.filterData = {
				sortBy: 'asc',
				searchValue: ''
			};
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		sortFilter(key, flag, fieldName) {
			for (let i = 0; i < this.fields.length; i += 1) {
				if (flag) {
					if (key === i) {
						this.fields[i].value1 = '';
						this.fields[i].sortBy = null;

						if (this.params.sortOption.fieldName === fieldName) {
							this.params.sortOption.sortType = null;
							this.params.sortOption.fieldName = '';
						}

						const index = this.params.filters.findIndex((f) => f.fieldName === fieldName);
						if (index >= 0) this.params.filters.splice(index, 1);

						break;
					}
				}

				if (key === i) {
					this.params.sortOption.sortType = this.fields[i].sortBy;
				}
			}
			if (!flag) this.params.sortOption.fieldName = fieldName;
			// Обнуляем пагинацию
			this.page = 0;
			this.params.currentPage = 0;
			this.getData(this.params);
		},
		search: debounce(function (item, array) {
			let obj = array.find((f) => f.fieldName === item.fieldName);

			if (array.length === 0 || !obj) {
				array.push(item);
			} else if (obj) {
				obj.value1 = item.value1;
			}

			for (let i = 0; i < this.params.filters.length; i += 1) {
				if (!this.params.filters[i].value1) {
					this.params.filters.splice(i, 1);
				}
			}

			this.getData(this.params);
		}, 1000),
		getData(params, url) {
			this.isLoading = true;

			this.$http
				.post(url, {
					filters: [
						...params.filters,
						{
							fieldName: 'RegionId',
							filterType: 1,
							filterValueType: 1,
							value1: this.getAuthUser.regionId,
							value2: ''
						}
					],
					sortOption: {
						fieldName: params.sortOption.fieldName,
						sortType: params.sortOption.sortType
					},
					pageSize: params.pageSize,
					currentPage: params.currentPage
				})
				.then((res) => {
					// console.log(res);
					res.data.data.forEach((el) => {
						let item = el;
						item.fio = `${el.fname} ${el.iname} ${el.oname}`;
					});
					this.list = res.data.data;
					this.totalCount = this.rowsVisible ? Math.ceil(res.data.totalRecords / this.rowsVisible) : 1;
					this.isLoading = false;
				});
		},
		navigation(name, page) {
			this.page = page;
			this[name].currentPage = page;

			this.getData(this[name], this.url);
		},
		setAnketaLinkForUserOS(medicalId, user) {
			this.$http
				.post(`/OS/${user.medicalOrgSet ? 'SetAnketaLinkForUserOS' : `UnSetAnketaLinkFromUserOS?userId=${user.id}&medicalId=${medicalId}`}`, {
					userId: user.medicalOrgSet ? user.id : undefined,
					anketaId: user.medicalOrgSet ? '0db34b23-e53c-41b0-9326-ecb6477f5e3b' : undefined,
					medicalId: user.medicalOrgSet ? medicalId : undefined
				})
				.then((res) => {
					if (res.status === 200) {
						this.$toast.success(`Сотрудник ОС успешно ${user.medicalOrgSet ? 'назначен' : 'снят'}!`, {
							position: 'top-right',
							timeout: 5000,
							closeOnClick: true,
							pauseOnFocusLoss: true,
							pauseOnHover: true,
							draggable: true,
							draggablePercent: 0.6,
							showCloseButtonOnHover: false,
							hideProgressBar: false,
							closeButton: 'button',
							icon: true,
							rtl: false
						});
					}
				})
				.catch((error) => {
					if (error) {
						user.medicalOrgSet = !user.medicalOrgSet;
						this.$toast.error(error.response.data.split('at')[0].split(':')[1], {
							position: 'top-right',
							timeout: 10000,
							closeOnClick: true,
							pauseOnFocusLoss: true,
							pauseOnHover: true,
							draggable: true,
							draggablePercent: 0.6,
							showCloseButtonOnHover: false,
							hideProgressBar: true,
							closeButton: 'button',
							icon: true,
							rtl: false
						});
					}
				});
		}
	},
	created() {
		if (this.$route.query.activeTab) this.activeTab = this.$route.query.activeTab;
		if (this.$route.query.nameMo) this.nameMo = this.$route.query.nameMo;
		if (this.$route.query.medicalId) this.medicalId = this.$route.query.medicalId;
		if (this.$route.query.url) {
			this.url = this.$route.query.url;
			this.getData(this.params, this.$route.query.url);
		}
	}
};
</script>

<style>
.toggle-checkbox:checked {
	@apply: right-0 border-blue-500;
	right: 0;
	border-color: #3b82f6;
}
.toggle-checkbox:checked + .toggle-label {
	@apply: bg-blue-500;
	background-color: #3b82f6;
}

.fade-enter {
	opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 500ms ease-out;
}

.fade-leave-to {
	opacity: 0;
}

li.arrow::before {
	@apply block w-2 h-2 mt-1.5 mr-2 border-b-2 border-l-2 border-gray-400;
	content: '';
	transform: rotate(45deg);
}
.arrow:last-child::before {
	transform: rotate(-135deg);
}

.custom-input:not(:checked) {
	display: none;
}

.custom-input:checked::after {
	content: '';
	position: absolute;
	display: block;
	width: 17px;
	height: 17px;
	top: -2px;
	left: -2px;
	background: url('~@/assets/img/filter-tick.svg') no-repeat;
	z-index: 40;
}
</style>

<style scoped>
.table-cell {
	@apply flex flex-wrap p-5 px-7 text-sm text-left;
}
</style>
